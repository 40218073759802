import React from "react";
import "./HomeScreen.css";
import logo from "../images/logo.png";
import Spline from "@splinetool/react-spline";

function HomeScreen({ scrollPosition, onPopupOpen }) {
  const scrollDirection = scrollPosition > 250 ? "down" : "up";

  const handlePlaystoreButtonClick = () => {
    onPopupOpen();
  };

  const handleAppstoreButtonClick = () => {
    onPopupOpen();
  };

  return (
    <div className="home-screen">
      <div className="home-content">
        <div className={`home-text ${scrollDirection}`}>
          <img className="logo" src={logo} alt="logo" />
          <p className="text">
            Discover Bharat's No 1 Gig working app! KrewsUp simplifies the work
            for both Hosts and Aspirants. Whether you're a Host looking to post
            the requirement or an eager Aspirant seeking opportunities, we've
            got you covered. Easy coordination and faster payments EOD for
            everyone, all in one place with KrewsUp!
          </p>
          <div className="buttons">
            <div>
              <div className="button-border-playstore"></div>
              <div
                className="playstore-button"
                onClick={handlePlaystoreButtonClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className="icon"
                  viewBox="0 0 512 512"
                >
                  <path d="M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z"></path>
                </svg>
                <span className="texts">
                  <span className="text-1">GET IT ON</span>
                  <span className="text-2">Google Play</span>
                </span>
              </div>
            </div>

            <div>
              <div className="button-border-appstore"></div>
              <div
                className="appstore-button"
                onClick={handleAppstoreButtonClick}
              >
                <svg
                  className="appstore-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  height="25px"
                  viewBox="0 0 256 315"
                >
                  <path d="M213.803 167.03c.442 47.58 41.74 63.413 42.197 63.615c-.35 1.116-6.599 22.563-21.757 44.716c-13.104 19.153-26.705 38.235-48.13 38.63c-21.05.388-27.82-12.483-51.888-12.483c-24.061 0-31.582 12.088-51.51 12.871c-20.68.783-36.428-20.71-49.64-39.793c-27-39.033-47.633-110.3-19.928-158.406c13.763-23.89 38.36-39.017 65.056-39.405c20.307-.387 39.475 13.662 51.889 13.662c12.406 0 35.699-16.895 60.186-14.414c10.25.427 39.026 4.14 57.503 31.186c-1.49.923-34.335 20.044-33.978 59.822M174.24 50.199c10.98-13.29 18.369-31.79 16.353-50.199c-15.826.636-34.962 10.546-46.314 23.828c-10.173 11.763-19.082 30.589-16.678 48.633c17.64 1.365 35.66-8.964 46.64-22.262" />
                </svg>
                <span className="appstore-texts">
                  <span className="appstore-text-1">GET IT ON</span>
                  <span className="appstore-text-2">App Store</span>
                </span>
              </div>
            </div>
          </div>
        </div>
          <Spline className="spline"
          style={{ width: "200%" }}  scene="https://prod.spline.design/9roXTH3jjoHy36ZQ/scene.splinecode" />
      </div>
    </div>
  );
}

export default HomeScreen;
