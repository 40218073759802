import React, { useState } from "react";
import "./ServicesScreen.css";
import phone from "../images/phone2.png";

function ServicesScreen({ scrollPosition }) {
  const scrollDirection = scrollPosition > 1000 ? "down" : "up";

  const headings = ["Organize An Event", "Search For An Event"];
  const info = [
    "Simplify your event planning with a seamless hiring experience. Post your event details, specify your requirements, and attract a pool of qualified candidates ready to bring your vision to life. Efficiently manage applications, select the perfect individuals, and ensure a smooth, successful event with just a few clicks.",
    "Explore your valuable time!! Discover unique, flexible event opportunities that fit your schedule and interests. Browse a diverse range of events, from Birthday Parties to Corporate events, and land paid jobs in minutes. Assured safety for all aspirants. No applications, no fuss - just sign up, explore, and get chosen!",
  ];

  const [selectedOption, setSelectedOption] = useState(0);
  const [animationClass, setAnimationClass] = useState("active");
  const [contentToShow, setContentToShow] = useState(headings[selectedOption]);

  const handleButtonClick = (index) => {
    if (selectedOption !== index) {
      setAnimationClass("inactive");
      setTimeout(() => {
        setSelectedOption(index);
        setContentToShow(headings[index]);
        setAnimationClass("active");
      }, 300);
    }
  };

  return (
    <div className="services-screen">
      <div className="services-content">
        {/* Display only in smaller screens */}
        <div className="services-phone">
          <img className="phone" height="550px" src={phone} alt="phone" />
          <div
            className={`button button-heading button-upper ${
              selectedOption === 0 ? "active-button" : ""
            }`}
            onClick={() => handleButtonClick(0)}
          >
            Organize An Event
          </div>
          <div
            className={`button button-heading button-lower ${
              selectedOption === 1 ? "active-button" : ""
            }`}
            onClick={() => handleButtonClick(1)}
          >
            <p>Search For An Event</p>
          </div>
        </div>
        <div
          className={`content ${scrollDirection} ${animationClass} cards-container-big`}
        >
          <h1 className="heading">{contentToShow}</h1>
          <p className="text-content">{info[selectedOption]}</p>
        </div>

        {/* Display both cards only in smaller screens */}
        <div className="cards-container">
          <div
            className={`content ${scrollDirection} ${animationClass} content-0`}
          >
            <h1 className="heading">{headings[0]}</h1>
            <p className="text-content">{info[0]}</p>
          </div>

          <div className={`content ${scrollDirection} ${animationClass}`}>
            <h1 className="heading">{headings[1]}</h1>
            <p className="text-content">{info[1]}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesScreen;
