import React from "react";
import Footer from "./Footer";
import Spline from "@splinetool/react-spline";

import "./ContactScreen.css";
import phoneIcon from "../images/phone.png";
import mailIcon from "../images/mail.png";
import pinIcon from "../images/location.png";

function ContactScreen() {
  return (
    <div className="contact-us">
      <div className="contact-content">
        <div className="contact-infos">
          <h2 className="contact-us-text">Contact Us</h2>
          <div className="phone-contact contact">
            <img className="phone-icon icon" src={phoneIcon} alt="phone" />
            <p className="phone-info">+91 82960 58467</p>
          </div>
          <div className="mail-contact contact">
            <img className="mail-icon icon" src={mailIcon} alt="mail" />
            <p className="mail-info">krewsup@gmail.com</p>
          </div>
          <div className="location-contact contact">
            <img className="pin-icon icon" src={pinIcon} alt="location" />
            <p className="address-info">
              Shriram Shreyas Apartment, G2-401 Kodigehalli, <br />
              Telecom Layout, Vidyaranyapura, Bengaluru-560097, Karnataka,
              India.
            </p>
          </div>
        </div>
        <div className="puzzle">
          <Spline scene="https://prod.spline.design/06ehB9Cd-40bYdCa/scene.splinecode" />
        </div>
      </div>
      <Footer className="footer" />
    </div>
  );
}

export default ContactScreen;
