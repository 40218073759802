import React, { useState, useEffect } from "react";
import "./Navbar.css";
import kuttyLogo from "../images/kuttyLogo.png";
import Hamburger from "hamburger-react";

function Navbar() {
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const smoothScrollTo = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="navbar">
      <img className="kutty-logo" src={kuttyLogo} alt="logo" />
      {isMobile ? (
        <>
          <div className="burger-menu-icon">
            <Hamburger toggled={isOpen} toggle={setOpen} />
          </div>
          {isOpen && (
            <div className="mobile-buttons">
              <div
              className="link-text-mobile"
                onClick={() => {
                  smoothScrollTo("home");
                  setOpen(false);
                }}
              >
                Home
              </div>
              <div
              className="link-text-mobile"
                onClick={() => {
                  smoothScrollTo("services");
                  setOpen(false);
                }}
              >
                Services
              </div>
              <div
              className="link-text-mobile"
                onClick={() => {
                  smoothScrollTo("contact");
                  setOpen(false);
                }}
              >
                Contact
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="nav-buttons">
          <div className="link-text" onClick={() => smoothScrollTo("home")}>Home</div>
          <div className="link-text" onClick={() => smoothScrollTo("services")}>Services</div>
          <div className="link-text" onClick={() => smoothScrollTo("contact")}>Contact</div>
        </div>
      )}
    </div>
  );
}

export default Navbar;
