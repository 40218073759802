import React, { useState } from "react";
import "./Popup.css";
import confetti from "../images/confetti.gif";

function Popup({ onClose }) {
  return (
    <div className="popup">
      <div className="confetti-icon">
      <img className="confetti" src={confetti} alt="cele" />
      </div>
      <p className="popup-text">
        Welcome aboard to KrewsUp! Your journey with us is about to begin,
        and we're thrilled to have you on board. We're working diligently to get
        you access to the app as quickly as possible. Sit tight, and we'll have
        you exploring all the exciting features in no time! Thank you for
        choosing KrewsUp.
      </p>
      <div className="popup-button" onClick={onClose}><p className="button-text">Back to Website</p></div>
    </div>
  );
}

export default Popup;
